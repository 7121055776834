import React, { Component } from "react";
import PageLoaded from "../PageLoaded/PageLoaded";

import "./Parcours.css";

class Parcours extends Component {
  constructor() {
    super();
    this.state = {
      name: "Parcours",
      content: [],
    };
  }

  render() {
    return (
      <div className="parcours">
        <PageLoaded />
        <div className="parcours-presentation">
          <div
            className="image"
            style={{ backgroundImage: "url('/img/presentation.png')" }}
          ></div>
          <div className="text">
            <p>
              Né au Portugal en 1953, Rui Sampaio partage son enfance entre son
              pays et l’Afrique, puis parcourt l’Europe avant de s’établir à
              Paris dans les années 80.
            </p>
            <p>
              Il exerce alors différents métiers: étalagiste, assistant
              décorateur, créateur de bijoux. En parallèle, il réalise des
              peintures et des sculptures qu'il expose dans des lieux
              alternatifs.
            </p>
            <p>
              A la recherche d’un atelier, il s’installe en 94 dans l'Hérault.
              Il vit et travaille actuellement à Saint André de Sangonis près de
              Montpellier.
            </p>
          </div>
        </div>

        <div className="parcours-expositions">
          <div className="text">
            <div className="title">Expositions</div>

            <div className="annee">
              <div className="date">2020</div>
              <ul>
                <li>
                  Voyage Singulier – Centre d’Art Contemporain - Bédarieux
                </li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2019</div>
              <ul>
                <li>
                  Transmutation - MJC Monplaisir dans le cadre de la 8BHN - Lyon
                </li>
                <li>
                  15ème Semaine de la Peinture Européenne en Occitanie - Maison
                  de l’Europe – Montpellier et Sète
                </li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2015</div>
              <ul>
                <li>
                  13 ème Festival International d’Art Singulier - Roquevaire
                </li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2013</div>
              <ul>
                <li>
                  Aux sources de la BHN - Espace d'exposition du Centre
                  Hospitalier St Joseph St Luc - Lyon
                </li>
                <li>Espace culturel du CHRU de Montpellier</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2012</div>
              <ul>
                <li>
                  Festival Singulièrement Vôtre - MPT Frédéric CHOPIN -
                  Montpellier
                </li>
                <li>fondation A.N.P.Q - Péret</li>
                <li>Galerie Otéro - La Colle sur Loup</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2011</div>
              <ul>
                <li>
                  Hors concept - La magie des matériaux - fondation A.N.P.Q -
                  Péret
                </li>
                <li>
                  Art nomade à Saint-Babel et exposition collective à la salle
                  du Fort - Le Broc
                </li>
                <li>Musée Centre d’Art - L’art en Marche - Lapalisse</li>
                <li>Galerie Singul’ART - Lyon</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2010</div>
              <ul>
                <li>Musée des Arts Buissonniers - Saint Sever du Moustier</li>
                <li>
                  Art Nomade au Vernet la Varenne et exposition collective à la
                  Halle aux grains - Issoire - en partenariat avec l’Art en
                  Marche
                </li>
                <li>Galerie Singul’ART - Lyon</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2009</div>
              <ul>
                <li>
                  5ème semaine de la peinture européenne en Languedoc-Roussillon
                  Maison de l’Europe - Montpellier
                </li>
                <li>
                  12ème Festival des voix de la méditerranée - Le Bocal - Lodève
                </li>
                <li>Galerie Vidourle prix - Sauve</li>
                <li>La Baraka - Ste Croix Vallée Française</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2008/2006</div>
              <ul>
                <li>Médiathèque Municipale de Gignac</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2005</div>
              <ul>
                <li>Le Bocal - Lodève</li>
              </ul>
            </div>

            <div className="annee">
              <div className="date">2003</div>
              <ul>
                <li>Salle d’exposition du Village du Livre - Octon</li>
              </ul>
            </div>
          </div>
          <div
            className="image"
            style={{ backgroundImage: "url('/img/expositions.png')" }}
          ></div>
        </div>
      </div>
    );
  }
}

export default Parcours;
