import React, {Component} from "react";
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
import PageLoaded from "../PageLoaded/PageLoaded";

import './Peintures.css'
import photos from './Peintures.json'

class Peintures extends Component {
    constructor() {
        super();

        this.state = {
            name: 'Peintures',
            content: [],
            currentImage: 0
        };

        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
    }

    openLightbox(event, obj) {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }

    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    goto(idx) {
        this.setState({
            currentImage: idx,
        });
    }

    render() {
        return (
            <div className="peintures">
                <PageLoaded/>
                <article>
                    <Gallery photos={photos} onClick={this.openLightbox} columns={3} margin={5}/>
                    <Lightbox images={photos}
                              onClose={this.closeLightbox.bind(this)}
                              onClickPrev={this.gotoPrevious.bind(this)}
                              onClickNext={this.gotoNext.bind(this)}
                              currentImage={this.state.currentImage}
                              isOpen={this.state.lightboxIsOpen}
                              showThumbnails={true}
                              imageCountSeparator={"/"}
                              preloadNextImage={true}
                              onClickThumbnail={this.goto.bind(this)}
                    />
                </article>
            </div>
        );
    }
}

export default Peintures;
