import React, {Component} from "react";
import { NavLink } from 'react-router-dom'
import "./MenuItem.css";

class MenuItem extends Component {
    render() {
        return (
            <li className={"menu-item" + (this.props.opened ? ' menu-item--opened' : '')}>
                <NavLink to={this.props.item.url} activeClassName="selected">{this.props.item.label}</NavLink>
            </li>
        );
    }
}

export default MenuItem;
