import React, {Component} from "react";
import PageLoaded from "../PageLoaded/PageLoaded";
import "./Accueil.css"

class Accueil extends Component {
    constructor() {
        super();
        this.state = {
            name: 'Accueil',
            content: []
        };
    }

    render() {
        return (
            <div className="accueil">
                <PageLoaded/>
                <img className="accueil-img" src="./img/accueil-2019.png" alt="sculptures"/>
                <p className="accueil-text">Transcendant les matières, par les vertus de l'assemblage
                    instinctif des formes et d'un chromatisme débordant, Rui Sampaio compose un monde d'une densité
                    jubilatoire. Méfiez-vous de l'apparente bonhomie de ses personnages : ils montent une garde sans
                    faille devant ce coeur de poète du quotidien qui a choisi, une fois pour toutes, que le monde
                    serait un rêve.
                    <span className="accueil-author">Carlo Roccella</span>
                </p>
            </div>
        );
    }
}

export default Accueil;
