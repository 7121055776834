import React, {Component} from "react";
import PageLoaded from "../PageLoaded/PageLoaded";

import './Videos.css';

class Videos extends Component {
    constructor() {
        super();
        this.state = {
            name: 'Videos',
            content: []
        };
    }

    render() {
        return (
            <div className="videos">
                <PageLoaded/>
                <article>
                    <iframe src="https://www.youtube.com/embed/AuEwKRneQks" frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen title="Rui Sampaio"/>
                </article>
            </div>
        );
    }
}

export default Videos;
