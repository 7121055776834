import { Component } from "react";


class PageLoaded extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
        window.EE.emit('menu-close');
    }

    render() {
        return null
    }
}

export default PageLoaded;